import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import "../components/blog-posts.css"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.coreEntry
    const siteTitle = this.props.data.site.siteMetadata.title
    const tagline = this.props.data.site.siteMetadata.tagline
    const { previous, next } = this.props.pageContext
    const menu = this.props.data.site.siteMetadata.menuLinks

    const rawDate = post.frontmatter.date
    //console.log("rawDate: " , rawDate)
    const hasSharingImage = post.frontmatter.sharingImage

    const dateOptions = {year: "numeric", month: "long", day: "2-digit"}
    const niceDate = new Intl.DateTimeFormat("en-US", dateOptions).format(new Date(rawDate))
      
      

    return (
      <Layout isblog={this.props.isBlog} location={this.props.location} title={siteTitle} menuLinks={menu} tagline={tagline}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          postDescription={post.frontmatter.description}
          sharingImage= {(hasSharingImage) ? post.frontmatter.sharingImage.publicURL : ''}
        />
        <article class="h-entry">

            <h1 class="p-name">
              <a class="u-url" href={this.props.location.href}>{post.frontmatter.title}</a>
            </h1>
            <p className="pub-details">
              <span class="p-author">{post.frontmatter.author}</span> / Published: <time class="dt-published" datetime={rawDate}>{niceDate}</time>
            </p>
          <section className="e-content" dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          
        </article>
      
       

       {  post.frontmatter.isBlog && 
       
  
        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
          }
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        tagline
        menuLinks {
          name
          link
        }
      }
    }
    coreEntry: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        description
        author
        isBlog
        sharingImage {
          publicURL
        }
      }
    }
    allWebMentionEntry(filter: {wmTarget: {eq: $slug}}) {
      edges {
        node {
          wmTarget
          wmSource
          wmProperty
          wmId
          type
          url
          likeOf
          author {
            url
            type
            photo
            name
          }
          content {
            text
          }
        }
      }
    }
  }
`
